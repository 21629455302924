import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';

// import "./list-item.scss";

const Item = styled.li`   
  list-style-type: none;
  background-color: ${(props) => props.active ? theme.lightGray : 'unset'};
  transition: all 0.2s ease-in-out!important;
  margin: 1em 0;
  
  &:hover{
    cursor: ${(props) => props.active ? 'default' : 'pointer '};
  }
  `;
const ItemLink = styled.a`
  padding: 1em;
  display: block;
  text-decoration: none;
  transition: all 0.2s ease-in-out!important;
  color: ${(props) => props.active ? theme.primary : theme.dark}!important;
  
  &:hover{
    color: ${theme.primary};
  }
`;

const Title = styled.h5`
  color: ${(props) => props.active ? theme.primary : theme.dark}!important;
`;
const Subtitle = styled.div`
  color: ${(props) => props.active ? theme.darkBlue : theme.muted}!important;
`;

const ListItem = (props) => {
  return (
    <Item style={props.style} active={props.active}>
      <ItemLink
        onClick={props.onClick}
        active={props.active}
      >
        <Title active={props.active}>
          {props.title}
        </Title>
        <Subtitle active={props.active}>
          {props.subtitle}
        </Subtitle>
        {props.children}
      </ItemLink>
    </Item>
  );
};

ListItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.array,
  onClick: PropTypes.func,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ListItem;

// TODO: make pointer different on active component - no pointer
