import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listPublications} from 'src/graphql/content';
import PlaceholderImage from 'images/placeholder.jpg';
import {Storage} from 'aws-amplify';

/**
 * Returns all active publications
 * @return {object} an object with multiple publications
 */
export default function usePublications() {
  const [publications, setPublications] = useState();

  useEffect(() => {
    if (publications?.reduce((p, acc) => p.image || acc)) {
      const updatedPublications = publications?.map(async (publication) => {
        if (!publication.image) {
          if (publication.imageUrl) {
            return Storage.get(publication.imageUrl, {level: 'public'})
                .then((img) => ({...publication, image: img}))
                .catch((e) => Promise.reject(new Error(e)));
          } else {
            return {...publication, image: PlaceholderImage};
          }
        } else {
          return publication;
        }
      });
      updatedPublications && Promise.all(updatedPublications).then((p) => {
        if (JSON.stringify(p) !== JSON.stringify(publications)) {
          console.log(p, publications);
          setPublications(p);
        }
      });
    }
  }, [publications]);

  useEffect(() => {
    if (!publications) {
      const getPublications = async () => {
        API.graphql({
          query: listPublications,
        })
            .then((data) => data.data.listPublications.items)
            .then((publications) => setPublications(publications))
            .catch((e) => console.error(e));
      };

      getPublications();
    }
  });

  return publications;
}
