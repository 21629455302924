import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {Col, Row} from 'react-bootstrap';

import {theme} from 'src/styles/theme';

const InspectPaneWrapper = styled((props) => <Row {...props} />)`
  height: 94vh;
`;

const InspectCol = styled((props) => <Col {...props} />)`
  height: 100%;
  overflow: scroll;
  box-shadow: ${(props) => !props.left ? '0 0 3rem 0 rgba(0, 0, 0, 0.08)' : ''};
  z-index: ${(props) => props.left ? 10 : 'unset'};
  padding: ${(props) => !props.left ? '3em' : 'unset'}!important;
  background-color: ${(props) => !props.left ? theme.xlight : theme.bodyBg};
`;

const InspectPane = (props) => {
  return (
    <InspectPaneWrapper>
      {props.children}
    </InspectPaneWrapper>
  );
};

InspectPane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const InspectPaneCol = (props) => {
  return (
    <InspectCol
      lg={6}
      className={props.className}
      left={props.left === true || props.right !== true}
    >
      {props.children}
    </InspectCol>
  );
};

InspectPaneCol.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  left: PropTypes.bool,
  right: PropTypes.bool,
};

InspectPane.Pane = InspectPaneCol;

export default InspectPane;


// nice gradient
// background: linear-gradient(130deg, #e74c3c 0, #78378c 100%)
