import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Container, ListGroup} from 'react-bootstrap';
import Markdown from 'react-markdown';

import usePublications from 'src/functionality/publication-provider';

import Page from 'src/components/functional/page';
import InspectPane from 'src/components/functional/inspect-pane';
import ListItem from 'src/components/functional/list-item';
import {theme} from 'styles/theme';
import styled from 'styled-components';
import {getResource} from 'src/functionality/resources-provider';
import Tile from 'src/components/functional/tile';

import 'styles/legal.scss';

const DownloadsWrapper = styled.div`
  display: flex;
  align-items: top;
  background-color: ${theme.bodyBg};
  padding: 1em;
  margin: 0.5em 0;
  & > div{
    flex-grow: 1;
  }
`;

const fileDownloads = {
  'Our Health Our Way': [
    {
      title: 'Our Health Our Way 3rd Edition',
      url: 'resources/arbias-publications/Our-Health-Our-Way-3rd-edition.pdf',
    },
  ],
  'Looking Forward: ABI': [
    {
      title: 'Looking Forward: ABI',
      url: 'resources/arbias-publications/Looking-Forward-ABI-2nd-edition.pdf',
    },
  ],
  'Looking Forward: Alcohol & Drug Related Brain Impairment': [
    {
      title: 'Looking Forward 2nd Edition',
      url: 'resources/arbias-publications/Looking-Forward.pdf',
    },
  ],
};


const PublicationsPage = () => {
  const [activePublication, setActivePublication] = useState();
  const publications = usePublications();

  useEffect(() => {
    if (publications) {
      if (window.location.hash === '') {
        setActivePublication(publications[0]);
      } else {
        setActivePublication(publications.filter((publication) =>
          publication.slug === window.location.hash.substring(1))[0]);
      }
    }
  }, [publications]);

  return (
    <Page noFooter>
      <Container fluid>
        <InspectPane>
          <InspectPane.Pane left>
            <h1 className="text-primary display-1">&nbsp;Publications</h1>
            <ListGroup className="custom-list-group publications-list-group">
              {publications && activePublication &&
                publications.map((publication, key) => (
                  <>
                    <hr className="list-divider" />
                    <ListItem
                      title={publication.title}
                      subtitle={publication.date}
                      key={key}
                      onClick={() => {
                        setActivePublication(publication);
                        window.location = `#${publication.slug}`;
                      }}
                      active={activePublication.id === publication.id}
                    >
                      {publication.excerpt}
                    </ListItem>
                  </>
                ))
              }
            </ListGroup>
          </InspectPane.Pane>
          <InspectPane.Pane right={true}>
            {activePublication &&
              <div>
                <h2>{activePublication.title}</h2>
                <Markdown>
                  {activePublication.body}
                </Markdown>
                {
                  fileDownloads[activePublication.title] &&
                <DownloadsWrapper>
                  {fileDownloads[activePublication.title].map((download, key) =>
                    <Tile
                      key={key}
                      isRounded={true}
                      centered={true}
                      type='document'
                      onClick={() =>
                        getResource(download.url)
                            .then((url) => window.open(url))}
                      title={download.title}
                    />,
                  )}
                </DownloadsWrapper>
                }
              </div>
            }
          </InspectPane.Pane>
        </InspectPane>
      </Container>
    </Page>
  );
};

PublicationsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default PublicationsPage;
